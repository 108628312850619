<template>
  <SeeYouSoon />
</template>

<script>
import SeeYouSoon from "../components/SeeYouSoon";

import {computed, reactive} from "vue";
import {useHead} from "@vueuse/head";

export default {
  name: "Manhattan",
  components: {
    SeeYouSoon
  },
  setup() {
    const siteData = reactive({
      robots: "noindex",
    })

    useHead({
      meta: [
        {
          name: `robots`,
          content: computed(() => siteData.robots)
        },
      ],
    })
  }
}
</script>

<style scoped>

</style>
